<script>
import simplebar from "simplebar-vue";
import Vue from 'vue'
import helper from "@/assets/helpers/helper";
import ReportPlatform from "@/components/popups/reportPlatform";
import ShareBadge from "@/components/popups/shareBadge";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {

      enabled:false,
      loadNotif:false,
      isSearch:false,
      load:false,
      is_login:false,
      resultats:[],
      loadIn:false,
      sliderPrice: 800,
      catSearch:'users',
      itemSearch:'',
      badges_count: 0,
      notifications:[],
      nbr:0,
      badge:{},
      show : false

    };
  },
  watch:{
    catSearch: function () {
      this.show=false
      this.itemSearch = ''
    },
    "$store.state.user":function(){
      console.log("user_id",this.$store.state.user.user_id)
      var channel = this.$store.state.pusher_crisis.subscribe('private-users_'+this.$store.state.user.user_id);
      channel.bind('badge_unlocked',  (data) =>  {
        console.log("new badge", data)
        this.badges_count +=1
        this.$store.commit('badge',data)

        this.$root.$emit('bv::toggle::modal', 'modal-share-badge')
      });
    }
  },
  components: {ShareBadge, ReportPlatform, simplebar },
  directives: {
    'click-outside': {
      bind: function(el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}'` }

          console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler

        // add Event Listeners
        document.addEventListener('click', handler)
      },

      unbind: function(el) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    }
  },

  created(){
    this.loadInfo()
    this.loadNotifications()
  },
  methods: {
    async loadInfo(){
      let u = await helper.getAll('me')
      if(u) {
        this.loadIn = true
        this.is_login = true
        u.profil.email = u.email
        u.profil.is_vip = u.is_vip;
        this.badges_count = u.badges_count
        this.$store.commit('user',u.profil)
        if(u.company) this.$store.commit('company',u.company.company)
        if(u.team) this.$store.commit('team',u.team.team)
      }


    },
    async loadNotifications() {
      let r = await helper.getAll('notifications')
      if(r) {
        this.nbr = r.count
        Object.values(r.notifications).forEach(x => {
          this.notifications = this.notifications.concat(Object.values(x));
        });
        this.loadNotif = true
      }

    },
    async search(){
      this.load = true
      this.isSearch = false
      this.show = true
      let r = []
      if(this.catSearch === 'users') r = await helper.postData(this.catSearch+'/search',{'username':this.itemSearch})
      else r = await helper.postData(this.catSearch+'/search',{'name':this.itemSearch})
      this.resultats = await r.data
      this.load = false
      this.isSearch = true

    },

    logout(){
      this.$http
              .post('logout')
              .then(() => {
                localStorage.removeItem("token")
                delete Vue.prototype.$http.defaults.headers.common['Authorization']
                this.is_login=false
                this.$router.push({name:'Login'})

              })
              .catch(error => {
                console.log(error)
              })
    },
    async read(notif_id,index){
      let r = await helper.postReturnData('notifications/'+notif_id+'/read')
      if(r) {
        this.notifications[index].is_read = 1
        this.nbr--
        this.$forceUpdate();
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    outside: function() {
        this.show = false;
        this.resultats=[]

    },
    itemProfil(item){
      let name=''
      let item_id;
      if(this.catSearch === 'users') {
        name='ProfilUser'
        item_id = item.user_id
      }
      if(this.catSearch === 'teams')
      {
        name='ProfilTeam'
        item_id = item.id
      }
      if(this.catSearch === 'challenges')
      {
          name='Training'
          item_id = item.id
      }
      if(this.catSearch === 'companies')
      {
          name='ProfilCompany'
          item_id = item.id
      }
      if(this.catSearch === 'competitions')
      {
          name='ProfilCompetition'
          item_id = item.id
      }
      this.show = false;
      this.$router.push({name:name,params:{id:item_id}})
    }
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link :to="{ name: 'starter'}" class="logo logo-dark">
        <span class="logo-sm">
              <img src="@/assets/images/logo-dark-icon.png" alt height="40" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="40" />
            </span>
          </router-link>
          <router-link  :to="{ name: 'Dashboard'}" class="logo logo-light">
              <span class="logo-sm">
              <img src="@/assets/images/logo-light-icon.png"  alt height="34" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="38" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-md  px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
       <div v-click-outside="outside" >
        <form class="app-search d-none d-lg-block" @submit.prevent="">
          <div class="position-relative">
            <div class="input-group">
              <div class="input-group-prepend">
                <label class="input-group-text " style="padding-right: 26px !important; border-radius: 18px 0 0 18px;">
                  <span class="bx bx-search-alt" :class="(load) ? 'text-warning': ''"></span>
                </label>
              </div>
              <select class="custom-select" style="max-width: 140px;" v-model="catSearch">
                <option value=""  disabled>Category</option>
                <option value="users" > {{$t('navbar.search.users')}}</option>
                <option value="teams" > {{$t('navbar.search.teams')}}</option>
                <option value="competitions"> {{$t('navbar.search.competitions')}}</option>
<!--                <option value="companies"> {{$t('navbar.search.companies')}}</option>-->
              </select>
              <input type="text"  class="form-control" v-model="itemSearch"  @keyup.enter="search"/>
              <button class="btn-primary" type="button" style=" border-radius: 0 18px 18px 0;" @click="search">
                {{(load) ? $t('navbar.search.wait'): $t('navbar.search.text')}}
              </button>
            </div>
          </div>
        </form>
        <div  v-show="show"
               class="position-absolute p-3 card " style="top:70px;width:35%">

            <div class="row mb-2 align-items-center">
              <div class="col">
                <i class="fa fa-times fa-1x float-right" style="cursor: pointer" @click="show = false"></i>
                <h6 class="">{{$t('result')}} :</h6>

              </div>
            </div>

          <simplebar style="max-height: 230px;" v-if="isSearch && resultats.length">
            <div v-for="r of resultats" :key="r.id">
            <a class="text-reset notification-item" @click="itemProfil(r)">
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 link" v-if="catSearch=='users'">{{r.username}}</h6>
                  <h6 class="mt-0 link" v-else>{{r.name}}</h6>
                </div>
              </div>
            </a>
            <hr>
            </div>

          </simplebar>
          <div v-else-if="isSearch">Try with other word</div>
        </div>
       </div>

      </div>
        <div class="d-flex">
          <div class="dropdown  d-lg-inline-block ml-1">
            <button type="button" class="btn header-item noti-icon" :title="$t('report.title')" v-b-modal.modal-bug>
              <i class="bx bx-bug"  ></i>
            </button>
          </div>

          <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge badge-danger badge-pill" v-if="nbr!=0">{{nbr}}</span>
          </template>

          <div class="p-3" >
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ $t('navbar.dropdown.notification.text')}}</h6>
              </div>
<!--              <div class="col-auto">-->
<!--                <a href="#" class="small">{{ $t('navbar.dropdown.notification.subtext')}}</a>-->
<!--              </div>-->
            </div>
          </div>
          <simplebar style="max-height: 230px;">
            <div  class="media border-bottom text-reset notification-item p-2" :class="{'not_read':notif.is_read == 1 && notif.user_notif_id}" v-for="(notif,index) in notifications" :key="notif.id">

                <div class="media-body">
<!--                  <h6 class="mt-0 mb-1">{{$t(notif.type.toString())}}</h6>-->
                  <div class="font-size-12 text-muted">
                    <p class="mb-1" v-if="notif.user_trigger && (notif.type != 'join_competition')"> <router-link :to="{name:'ProfilUser',params:{id:notif.user_trigger}}">{{JSON.parse(notif.description).username}}</router-link> <span v-if="notif.count>1">{{$t('and')}} {{notif.count}} {{$t('other')}}</span> {{$t('notifs.'+notif.type)}}</p>
                    <p class="mb-1" v-else>{{$t('notifs.'+notif.type)}} {{JSON.parse(notif.description).content}}</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{notif.time_diff}}
                    </p>
                  </div>
                </div>
                <div class="avatar-xs ms-3">
                  <span class="avatar-title  rounded-circle font-size-16" role="button" title="read"  @click="read(notif.user_notif_id,index)" :class="{'bg-primary':notif.is_read == 0  ,'bg-white disabled':notif.is_read == 1,'bg-warning disabled':!notif.user_notif_id}">
                    <i class="fas fa-bookmark" :class="{'text-primary':notif.is_read == 1}"></i>
                  </span>
                </div>

            </div>
          </simplebar>
          <div class="p-2 border-top" v-if="notifications.length>=10">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i>
              {{ $t('navbar.dropdown.notification.button')}}
            </a>
          </div>
            <p class="text-center my-3" v-if="notifications.length == 0 && loadNotif">
              {{ $t('validation.no_notif') }}
            </p>
        </b-dropdown>
          <div class="dropdown  d-inline-block ml-1 mt-3 mr-1"  v-if="!is_login && loadIn" >
            <button type="button" class="btn btn-outline-success" @click="$router.push({name:'Login'})">{{ $t('navbar.login') }}</button>
          </div>
          <div class="d-flex ml-1 mt-3 mr-1"  v-if="!loadIn" style="width: 200px">
            <b-skeleton type="avatar" class="col-sm-2 mr-2"></b-skeleton>
            <b-skeleton class="mt-2" width="100%"></b-skeleton>
          </div>
        <b-dropdown right variant="black" toggle-class="header-item " v-if="is_login && loadIn">
          <template v-slot:button-content>
            <b-avatar :src="$store.state.user.avatar"  v-if="$store.state.user.avatar"></b-avatar>
            <b-avatar v-else variant="primary"  :text="($store.state.user.username).substr(0, 1)" class="align-baseline "></b-avatar>
            <span class="d-none d-xl-inline-block mx-2">{{$store.state.user.username}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>

          <b-dropdown-item to="/profile">
              <i class="bx bx-user font-size-16 align-middle mr-1"></i>
              {{ $t('navbar.dropdown.henry.list.profile') }}
          </b-dropdown-item>

          <b-dropdown-item to="/my-competitions">
              <i class="bx bx-flag font-size-16 align-middle mr-1"></i>
              {{ $t('navbar.dropdown.henry.list.my_competitions') }}
          </b-dropdown-item>

          <b-dropdown-item :to="'/companies/'+$store.state.company.id" v-if="$store.state.company.id">
            <i class="bx bx-building font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.dropdown.henry.list.company') }}
          </b-dropdown-item>

          <b-dropdown-item :to="'/teams/'+$store.state.team.id" v-if="$store.state.team.id">
            <i class="bx bx-building font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.dropdown.henry.list.team') }}
          </b-dropdown-item>
          <b-dropdown-item class="d-block" to="/access">
            <i class="bx bx-lock-alt font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.dropdown.henry.list.access') }}
          </b-dropdown-item>
          <b-dropdown-item class="d-block" to="/badges">
            <i class="bx bxs-badge font-size-16 align-middle mr-1"></i>
            <span class="badge badge-pill badge-soft-success font-size-14  float-right">{{badges_count}}</span>
            {{ $t('navbar.dropdown.henry.list.badges') }}
          </b-dropdown-item>
          <b-dropdown-item to="/saved_posts">
            <i class="bx bx-save font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.dropdown.henry.list.saved_posts') }}
          </b-dropdown-item>
          <b-dropdown-item :to="{name: 'MyProfil', query: { s: '1' } }">
            <i class="bx bx-wrench font-size-16 align-middle mr-1"></i>
            {{ $t('navbar.dropdown.henry.list.settings') }}
          </b-dropdown-item>
          <b-dropdown-divider style="width: 200px"></b-dropdown-divider>
          <a  class="dropdown-item text-danger" @click="logout" style="cursor: pointer">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>

      </div>
    </div>
    <report-platform/>
    <share-badge />
  </header>
</template>

<style scoped>
.not_read{
  background-color: #232837!important;
}
.media:hover{
  background-color: #232837!important;
}
.disabled {
  pointer-events: none;
}

</style>