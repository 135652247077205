import { render, staticRenderFns } from "./nav-bar.vue?vue&type=template&id=70a531d2&scoped=true"
import script from "./nav-bar.vue?vue&type=script&lang=js"
export * from "./nav-bar.vue?vue&type=script&lang=js"
import style0 from "./nav-bar.vue?vue&type=style&index=0&id=70a531d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a531d2",
  null
  
)

export default component.exports